import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'simple-checkbox',
    template: `
        <div class="text-nowrap">
            <input id="{{idName}}" type="checkbox" [ngModel]="checked" (ngModelChange)="checkedEmitter.emit($event)" (keydown)="keyPressEmitter.emit($event)">
            <ng-container *ngIf="checkboxTitle">
                &nbsp;<label for="{{idName}}">{{ checkboxTitle }}</label>
            </ng-container>
        </div>
    `,
})
export class SimpleCheckboxComponent {
    @Input() idName = 'input';
    @Input() checked: boolean;
    @Output('checkedChange') checkedEmitter = new EventEmitter<boolean>();
    @Input() checkboxTitle: string;
    @Output('keyPress') keyPressEmitter = new EventEmitter<KeyboardEvent>();
}
